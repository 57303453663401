import prisma from "../../../prisma";
import UserService from "./user/UserService";

class SettingsService {
  public async getSettingsByName(name: string) {
    const setting = await prisma.settings.findUnique({
      where: { name: name },
      select: { value: true },
    });
    let value = setting?.value;

    return value;
  }
}

export default new SettingsService();
