// UserSessionProvider.tsx
import React, { createContext, useContext, useState, ReactNode } from "react";

interface UserSessionContextType {
  user: any; // Replace `any` with your actual user type, e.g., `User`
  setUser: (user: any) => void;
}

const UserSessionContext = createContext<UserSessionContextType | undefined>(
  undefined
);

interface UserSessionProviderProps {
  children: ReactNode;
  initialUser: any; // Replace `any` with your user type
}

export const UserSessionProvider = ({
  children,
  initialUser,
}: UserSessionProviderProps) => {
  const [user, setUser] = useState(initialUser);

  return (
    <UserSessionContext.Provider value={{ user, setUser }}>
      {children}
    </UserSessionContext.Provider>
  );
};

export const useUserSession = () => {
  const context = useContext(UserSessionContext);
  if (!context)
    throw new Error("useUserSession must be used within a UserSessionProvider");
  return context;
};
