export default class CommonUtils {
  static numberOfProperties = 40000;
  static appName = "Makazi Mapya";
  static baseURL = "https://makazimapya.com";
  static fallbackTitleSw = `Nunua na kupanga Nyumba na Viwanja Tanzania | ${this.appName} - TZ Real Estate`;
  static fallbackTitleEn = `Buy and Rent Homes and Plots in Tanzania | ${this.appName} - TZ Real Estate`;

  static fallbackDescriptionSw = `Pata chaguo lako la viwanja na nyumba zaidi ya ${this.commaSeparatedNumber(
    this.numberOfProperties
  )} Tanzania kwa bei nafuu. Nunua au panga leo na Makazi Mapya - rahisi, haraka, na ya kuaminika!`;

  static fallbackDescriptionEn = `Discover your choice of over ${this.commaSeparatedNumber(
    this.numberOfProperties
  )} houses and plots in Tanzania at affordable prices. Buy or rent today with Makazi Mapya - easy, fast, and reliable!`;

  static fallbackImageUrl = `${this.baseURL}/makazi-mapya-image.png`;

  static commaSeparatedNumber(num: number) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static timeAgo(dateIsoStr) {
    const date = new Date(dateIsoStr);
    const now = new Date();
    const millisecondsAgo = now.getTime() - date.getTime(); // Explicitly using getTime()
    const secondsAgo = Math.round(millisecondsAgo / 1000);

    // Less than a minute
    if (secondsAgo < 60) {
      return "just now";
    }

    // Convert to minutes
    const minutesAgo = Math.round(secondsAgo / 60);
    if (minutesAgo < 60) {
      return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
    }

    // Convert to hours
    const hoursAgo = Math.round(minutesAgo / 60);
    if (hoursAgo < 24) {
      return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    }

    // Convert to days
    const daysAgo = Math.round(hoursAgo / 24);
    if (daysAgo < 30) {
      return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
    }

    // Convert to months
    const monthsAgo = Math.round(daysAgo / 30);
    if (monthsAgo < 12) {
      return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
    }

    // Convert to years
    const yearsAgo = Math.round(monthsAgo / 12);
    return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
  }

  static cleanText(text) {
    //remove commas from numbers

    text = text.replace(/\n/g, " nl ");

    text = text.replace(/#/g, " hashtag ");
    text = text.replace("m²", " sqm ");
    text = text.replace("M²", " sqm ");
    text = text.replace("..", "");

    text = text.replace(/(\d),/g, "$1");
    // remove white spaces from numbers
    text = text.replace(/(\d)\s+(\d)/g, "$1$2");

    // Remove HTML tags and entities
    text = text.replace(/&nbsp;/gi, " ");
    text = text.replace(/&#\d+;/gi, " ");
    text = text.replace(/(<([^>]+)>)/gi, " ");

    // Normalize whitespace (replace new lines and multiple spaces with a single space)
    text = text.replace(/\s+/g, " ");

    // text = text.replace(":", " ");

    // Remove all special characters, but allow alphanumeric, spaces, and dots/commas within numbers
    text = text.replace(/[^\w\s.,]|(?<=\d)[,](?=\d)/gi, " ");

    // remove leading whitespace
    text = text.replace(/\s+/g, " ");

    // Trim leading and trailing whitespace
    text = text.trim();

    // Convert all text to lowercase
    text = text.toLowerCase();

    text = text.replace("tzs", " tzs ");
    text = text.replace("tsh", " tsh ");
    text = text.replace("$", " $ ");
    text = text.replace("tshs", " tshs ");
    text = text.replace("usd", " usd ");
    text = text.replace("/-", " ");
    text = text.replace("/=", " ");
    text = text.replace(/☑️/g, " ");
    text = this.addSpaceBetweenLetterAndNumber(text);

    return text;
  }

  static replaceEmojisWithSpace(text) {
    // Unicode ranges for emojis
    const emojiRegex =
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2B50}\u{2B55}\u{231A}-\u{231B}\u{2328}\u{23CF}\u{23E9}-\u{23F3}\u{23F8}-\u{23FA}\u{24C2}\u{25AA}-\u{25AB}\u{25B6}\u{25C0}\u{25FB}-\u{25FE}\u{2601}-\u{2604}\u{260E}\u{2611}\u{2614}-\u{2615}\u{2618}\u{261D}\u{2620}\u{2622}-\u{2623}\u{2626}\u{262A}\u{262E}-\u{262F}\u{2638}-\u{263A}\u{2640}\u{2642}\u{2648}-\u{2653}\u{2660}\u{2663}\u{2665}-\u{2666}\u{2668}\u{267B}\u{267F}\u{2692}-\u{2697}\u{2699}\u{269B}-\u{269C}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26B0}-\u{26B1}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26C8}\u{26CE}-\u{26CF}\u{26D1}\u{26D3}-\u{26D4}\u{26E9}-\u{26EA}\u{26F0}-\u{26F5}\u{26F7}-\u{26FA}\u{26FD}\u{2702}\u{2705}\u{2708}-\u{270D}\u{270F}\u{2712}\u{2714}\u{2716}\u{271D}\u{2721}\u{2728}\u{2733}-\u{2734}\u{2744}\u{2747}\u{274C}\u{274E}\u{2753}-\u{2755}\u{2757}\u{2763}-\u{2764}\u{2795}-\u{2797}\u{27A1}\u{27B0}\u{27BF}\u{2934}-\u{2935}\u{2B05}-\u{2B07}\u{2B1B}-\u{2B1C}\u{2B50}\u{2B55}\u{3030}\u{303D}\u{3297}\u{3299}]+/gu;

    // Replace all emojis in the text with a space
    return text.replace(emojiRegex, " ");
  }

  static addSpaceBetweenLetterAndNumber(text) {
    // Regular expression to find where a digit is immediately followed by a letter
    const regex = /(\d)([a-zA-Z])/g;

    // Replace such occurrences with the digit, a space, and the letter
    return text.replace(regex, "$1 $2");
  }

  static getCookieValue = (cookies: string, name: string) => {
    const value = `; ${cookies}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(";").shift();
  };
}
