import { AppProps } from "next/app";
import App from "next/app";
import React, { useEffect } from "react";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import "../../public/assets/css/tailwind.css";
import "../styles/globals.css";
import { Router } from "next/router";
import TranslationProvider from "@/TranslationProvider";
import { registerServiceWorker } from "@/utils/RegisterServiceWorker";
import { UserSessionProvider } from "@/UserSessionProvider";
import UserService from "@/server/services/user/UserService";
import CommonUtils from "@/utils/CommonUtils";
import { verifyToken } from "@/server/services/auth/SessionService";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({
  Component,
  pageProps,
  router,
}: AppProps & { pageProps: { initialUser: any } }) {
  const translations = require(`../locales/${router.locale}/common.json`);

  useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <UserSessionProvider initialUser={pageProps.initialUser}>
      <TranslationProvider translations={translations}>
        <Component {...pageProps} />
      </TranslationProvider>
    </UserSessionProvider>
  );
}

// Fetch the current user on the server side
MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const req = appContext.ctx.req;
  const res = appContext.ctx.res;
  const url = appContext.ctx.asPath.split("?")[0];

  const cookies = req?.headers?.cookie || "";
  const token = CommonUtils.getCookieValue(cookies, "token");

  let decodedToken = null;
  if (token) {
    decodedToken = await verifyToken(token);
  }

  const protectedPaths = ["/app-admin", "/agent-admin"];

  try {
    if (protectedPaths.includes(url) && !decodedToken) {
      res.writeHead(302, { Location: "/sign-in" });
    }

    if (decodedToken && url === "/sign-in") {
      res.writeHead(302, { Location: "/app-admin" });
    }

    if (!decodedToken && protectedPaths.includes(url)) {
      res.writeHead(302, { Location: "/sign-in" });
    }
  } catch (e) {
    console.log("Error in getInitialProps", e);
  }

  let initialUser = null;
  if (req) {
    initialUser = await UserService.getLoggedInUser(req);
  }

  return { ...appProps, pageProps: { ...appProps.pageProps, initialUser } };
};

export default MyApp;
