import prisma from "../../../../prisma";
import settingsService from "../settingsService";
import { verifyToken } from "../auth/SessionService";
import CommonUtils from "@/utils/CommonUtils";

class UserService {
  public async upsertFBAccount(
    email: string,
    fbId: string,
    name: string,
    igAccount: any,
    access_token: string,
    access_token_expires_at_ms: number
  ) {
    {
      try {
        const access_token_expires_at = new Date(
          Date.now() + access_token_expires_at_ms * 1000
        );

        access_token_expires_at.setDate(access_token_expires_at.getDate() - 10);

        const user = await prisma.user.findFirst({
          where: { fbEmail: email },
        });

        let userRes = null;
        if (user) {
          userRes = await prisma.user.update({
            where: { email: email },
            data: {
              name: name,
              fbToken: access_token,
              fbTokenExpiresAt: access_token_expires_at,
              fb_token_last_use: new Date(),
              fb_user_id: fbId,
              fb_ig_account_id: igAccount.id,
              fb_ig_account_username: igAccount.username,
            },
          });
        } else {
          userRes = await prisma.user.create({
            data: {
              email: email,
              fbId: fbId,
              name: name,
              fbEmail: email,
              fbToken: access_token,
              fbTokenExpiresAt: access_token_expires_at,
              fb_token_last_use: new Date(),
              fb_user_id: fbId,
              fb_ig_account_id: igAccount.id,
              fb_ig_account_username: igAccount.username,
            },
          });
        }

        return { success: true, message: "User added as admin", user: userRes };
      } catch (error) {
        throw error;
      }
    }
  }

  public async fbUserIsAdmin(email: string) {
    try {
      let fbAdmins = await settingsService.getSettingsByName("FB_ADMINS");

      let fbAdminsArray = fbAdmins.split(",");

      if (fbAdminsArray.indexOf(email) === -1) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  public async getUserByUuid(uuid: string) {
    try {
      let user = await prisma.user.findFirst({
        where: { uuid: uuid },
      });

      return user;
    } catch (error) {
      throw error;
    }
  }

  public async getLoggedInUser(req: any) {
    const cookies = req.headers.cookie || "";
    const token = CommonUtils.getCookieValue(cookies, "token");

    let decodedToken = null;
    if (!token) {
      return null;
    }

    decodedToken = await verifyToken(token);

    if (!decodedToken) {
      return null;
    }

    let user;

    try {
      if (decodedToken.userType === "admin") {
        user = await prisma.user.findFirst({
          where: { uuid: decodedToken.uuid },
        });
      } else {
        user = await prisma.seller.findFirst({
          where: { uuid: decodedToken.uuid },
        });
      }

      return user;
    } catch (error) {
      throw error;
    }
  }

  public async getUserAccessTokenToUse() {
    try {
      let user = await prisma.user.findFirst({
        where: {
          fbToken: {
            not: null,
          },
          fbTokenExpiresAt: {
            gte: new Date(),
          },
          OR: [
            {
              fb_token_last_use: null,
            },
            {
              fb_token_last_use: {
                lte: new Date(),
              },
            },
          ],
        },
        orderBy: {
          fb_token_last_use: "asc",
        },
      });

      this.updateTokenLastUse(user.email);

      return user;
    } catch (error) {
      throw error;
    }
  }

  async updateTokenLastUse(email: string) {
    try {
      let user = await prisma.user.update({
        where: {
          email: email,
        },
        data: {
          fb_token_last_use: new Date(),
        },
      });

      return user;
    } catch (error) {
      throw error;
    }
  }
}

export default new UserService();
